export const DOMISERVE_CONTACT_URL = "https://www.domiserve.com/507/votre-demande.htm"

export const DOMISERVE_TARIF_PRESTATIONS_URL =
    "http://www.domiserve.com/fileadmin/domiserve/MEDIA/PDF/Tarif_CESU_Domiserve.pdf"

export const DOMISERVE_CESU_ACCOUNT_NOTICE_URL =
    "https://www.domiserve.com/fileadmin/www.domiserve.com/MEDIA/PDF/Notice_Compte_CESU_2020.pdf"

export const CRCESU_HOME_URL = "www.cr-cesu.fr"
export const CRCESU_CONTACT_URL = "https://www.cr-cesu.fr/menu-haut/contactez-nous.html"
export const CRCESU_PERSONAL_AREA = "https://espacepersonnel.cr-cesu.fr/#/signUp"

export const CESU_URSSAF_URL = "www.cesu.urssaf.fr"

export const PAJ_EMPLOI_URSSAF = "www.pajemploi.urssaf.fr"

export const BENEFICIARY_AREA_URL = "https://espace-beneficiaire.edomiserve.com"
export const FINANCEUR_AREA_URL = "https://espace-financeur.edomiserve.com"
export const PRESTADOM_URL = "https://prestadom.edomiserve.com"
export const SIDOMI_URL = "https://espace-beneficiaire-service.edomiserve.com"
export const TRADE_AREA_URL = "https://espace-echange.edomiserve.com"
export const INTERVENANT_AREA_URL = "https://espace-intervenant.edomiserve.com"

export const APPLICATION_UNAVAILABLE_URL = "https://www.domiserve.com/indisponibilite"
